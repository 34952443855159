
.railway-siding-popup {
    p {
        margin-top: 5px !important;
    }

    h2 {
        margin: 5px 0 0 !important;
    }
}
