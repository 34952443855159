.main {
    position: absolute;
    left: 45%;
    z-index: 1000;
}

.main-container {
    position: relative;
    margin-top: 24px;
    overflow-y: auto;
    height: 500px;
    background-color: #f8f8f8;
    padding: 21px;
    margin-top: 18px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    padding: 21px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    text-wrap: wrap;
}

.single-heading {
    margin-bottom: 10px;
    font-weight: 900;
    font-size: 20px;
}

.singleDetail {
    margin-bottom: 20px;
}

.singleDetail p {
    margin-bottom: 8px;
    line-height: 1.6;
}

.singleDetail p span:first-child {
    font-weight: bold;
    margin-right: 5px;
    color: #333;
}

.singleDetail p span:last-child {
    color: #555;
}

.singleDetail p br {
    display: none;
}

.header {
    display: flex;
    justify-content: space-between;
}

.close-button {
    cursor: pointer;
}
