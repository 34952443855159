.filters {
    background-color: #fff;
    gap: 18px;
    display: flex;
    flex-direction: column;
    margin: 15px auto;


    .search {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;

    }

    .clearAllButton {
        position: absolute;
        top: 0.6rem;
        right: 1rem;
        background: transparent;
        border: none;
        color: #ff5c5c;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;

        // display: flex;
        // align-items: center;
        // justify-content: center;
        padding: 15px;
        &:hover {
            color: #ff5c5c;
        }

        &:focus {
            outline: none;
        }
    }


    .heading {
        font-weight: 600;
        padding: 0 10px;
        margin: 0;
        text-decoration: underline;
    }

    .filter {
        background-color: #f8f8f8;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;

        .filter-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
        }

        .fitler-content {
            display: flex;
            align-items: center;
            gap: 9px;

            .filter-name {
                font-size: 14px;

                &.pointer {
                    cursor: pointer;
                }
            }

            .filter-image {
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                flex-shrink: 0;
                background-color: #fff;
                filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.12));

                img {
                    &.width-and-height {
                        width: 25px;
                        height: 25px;
                        object-fit: contain;
                    }
                }
            }

            .filter-fields {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;
            }
        }

        .filter-values {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            background-color: #f8f8f8;
            padding: 4px 10px;

            .filter-value {
                display: inline-flex;
                padding: 4px 10px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 5px;
                border: 0.5px solid #9d9d9d;
                background: #fff;
                cursor: pointer;
            }

            .selected-filter {
                color: red;
                border-radius: 5px;
                border: 0.5px solid #900;
                background: rgba(153, 0, 0, 0.05);
                box-shadow: 5px 5px 5px 0px rgba(153, 0, 0, 0.05);
            }
        }

        input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 18px;
            height: 18px;
            border-radius: 4px;
            border-radius: 2px;
            background: #fff;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            cursor: pointer;
            position: relative;
            transform: translateY(2px);

            &::before {
                content: "";
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 3px;
                border-style: solid;
                border-color: transparent;
                border-width: 0px 0px 2px 2px;
                visibility: hidden;
            }

            &:checked {
                background-color: #990000;
                box-shadow: 0 0 5px #990000;

                &::before {
                    visibility: visible;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    /* Corrected rotation for tick icon */
                    border-color: white;
                }

                /* Adding drop shadow */
            }
        }

        .filter-selector {
            margin-left: 20px;

            input[type="checkbox"] {
                &.line {
                    background-color: #990000;
                    box-shadow: 0 0 5px #990000;

                    &::before {
                        position: absolute;
                        top: 50%;
                        visibility: visible;
                        width: 10px;
                        height: 2px;
                        /* Adjust the height to create a line */
                        background-color: white;
                        /* Set the color of the line */
                        border: none;
                        /* Remove the border */
                        transform: translate(-50%, -50%);
                        /* Center the line */
                    }
                }
            }
        }
    }
}
