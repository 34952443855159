.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.main-container {
    position: relative;
    margin-top: 24px;
    overflow-y: auto;
    width: 300px;
    background-color: #f8f8f8;
    padding: 21px;
    margin-top: 18px;
    background-color: rgba(255, 255, 255, 0.65);
    padding: 21px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    text-wrap: wrap;
}

.single-heading {
    margin-bottom: 10px;
    font-weight: bold;
}

.info-icon {
    margin-top: -10px;
    font-size: 20px;
    padding: 5px;
    color: rgba(72, 72, 72, 0.7);
    border-radius: 3px;
    background-color: white;
    cursor: pointer;
    transition: color 0.3s ease;
}
