.socio-economics-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  color: black;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 999;

  opacity: 0;
  transform: translateY(-50px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.socio-economics-show {
  opacity: 1;
}

.socio-economics-show:not(.has-socio-ecnomic) {
  transform: translateY(70px);
}

.socio-economics-show.has-socio-ecnomic {
  transform: translateY(10px);
}

.socio-economics-hide {
  opacity: 0;
  transform: translateY(-50px);
}

.socio-economics-close-btn {
  background: none;
  border: none;
  color: black;
  font-size: 18px;
  cursor: pointer;
}
